import axios from "axios";
import store from "@/store";

const api = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT
});

api.interceptors.request.use(
  async config => {
    store.dispatch("preloader/SET_LOADING");
    return config;
  },
  error => {
    store.dispatch("preloader/SET_LOADING");
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  response => {
    store.dispatch("preloader/UNSET_LOADING");
    return response;
  },
  error => {
    store.dispatch("preloader/UNSET_LOADING");
    return Promise.reject(error);
  }
);

export default api;

<template>
  <ASection class="o-interactive-map">
    <AColumns class="o-interactive-map__container is-gapless">
      <AColumn class="o-interactive-map__menu is-2-desktop is-2-widescreen">
        <OMenu :items="categories" />
      </AColumn>
      <AColumn
        class="o-interactive-map__places is-2-desktop is-2-widescreen"
        :class="{ 'is-block': showPlaces, 'is-hidden': !showPlaces }"
      >
        <router-view />
      </AColumn>
      <AColumn
        class="o-interactive-map__map"
        :class="{
          'is-8-desktop is-8-widescreen': showPlaces,
          'is-10-desktop is-10-widescreen': !showPlaces
        }"
      >
        <OMap :onLoad="onLoadMap" />
      </AColumn>
    </AColumns>
  </ASection>
</template>

<script>
import "@fortawesome/fontawesome-free/js/brands";
import "@fortawesome/fontawesome-free/js/fontawesome";
import { computed, inject, onMounted, ref, watch } from "vue";
import AColumn from "@/components/atoms/Column.vue";
import AColumns from "@/components/atoms/Columns.vue";
import ASection from "@/components/atoms/Section.vue";
import OMap from "@/components/organisms/Map.vue";
import OMenu from "@/components/organisms/Menu.vue";
import useFitToRadius from "@/composables/useFitToRadius";
import { useI18n } from "vue-i18n";
import usePlaceMarkers from "@/composables/usePlaceMarkers";
import useResetUserLocation from "@/composables/useResetUserLocation";
import { useRoute } from "vue-router";
import useShowPlaces from "@/composables/useShowPlaces";
import { useStore } from "vuex";

export default {
  name: "OInteractiveMap",
  components: {
    AColumn,
    AColumns,
    ASection,
    OMap,
    OMenu
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const store = useStore();
    const categories = computed(() => store.getters["places/categories"]);
    const places = computed(() => store.getters["places/places"]);
    const map = computed(() => store.getters["markers/map"]);
    const showPlaces = ref(false);

    const addMarker = inject("addMarker");
    const emptyMarkers = inject("emptyMarkers");
    const getInfoWindow = inject("getInfoWindow");
    const removeMarker = inject("removeMarker");
    const infoWindow = getInfoWindow();

    function checkShowPlaces() {
      showPlaces.value = useShowPlaces({ route });
    }

    function setMapInstance(m) {
      store.dispatch("markers/SET_MAP", m);
    }

    function getCategories() {
      if (!categories.value.length) {
        return store.dispatch("places/GET_CATEGORIES");
      }

      return Promise.resolve();
    }

    function getPlaces() {
      if (!places.value.length) {
        return store.dispatch("places/GET_PLACES");
      }

      return Promise.resolve();
    }

    const handleMarkers = async (reset = false) => {
      if (route.name === "Home") {
        if (reset) {
          await useResetUserLocation({
            addMarker,
            map: map.value,
            radius: 25,
            removeMarker,
            store,
            t
          });
        } else {
          useFitToRadius(25, map.value);
        }

        usePlaceMarkers({
          addMarker,
          emptyMarkers,
          infoWindow,
          map: map.value,
          places: places.value,
          t
        });
      }
    };

    const onLoadMap = async m => {
      setMapInstance(m);
      await getPlaces();
      handleMarkers();
    };

    onMounted(async () => {
      await getCategories();
      checkShowPlaces();
    });

    watch(
      () => route.path,
      async () => {
        checkShowPlaces();
        handleMarkers(true);
      }
    );

    return {
      categories,
      onLoadMap,
      showPlaces
    };
  }
};
</script>

<style lang="scss">
@import "~bulma/sass/elements/icon.sass";

.o-interactive-map {
  &__container {
    @extend %position-relative;
    height: 100%;
  }

  &__menu {
    @extend %position-relative;
    z-index: 11;

    .o-menu {
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  &__places {
    @extend %position-relative;
    border-left: 1px solid #dbdbdb;
    z-index: 10;

    .o-places {
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  &__map {
    z-index: 9;

    .m-map {
      @extend %position-absolute;
      @extend %width-full;
      @extend %zero-position;
      height: 100%;
    }
  }
}

@include media(">=desktop") {
  .o-interactive-map {
    &__map {
      .m-map {
        position: relative;
      }
    }
  }
}
</style>

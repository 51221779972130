import useCreateMarker from "./useCreateMarker";
import useFitToRadius from "./useFitToRadius";
import useGetUserLocation from "./useGetUserLocation";
import useSetMapCenter from "./useSetMapCenter";

const useResetUserLocation = async ({
  addMarker,
  map,
  radius,
  removeMarker,
  store,
  t
}) => {
  const markerId = "user-location";
  const location = await useGetUserLocation(store);

  removeMarker(markerId);

  addMarker(
    useCreateMarker({
      id: markerId,
      markerOptions: {
        icon: require("@/assets/user-location.png"),
        map: map,
        position: { lat: location.lat, lng: location.lng },
        title: t("Você está aqui")
      }
    })
  );

  await useSetMapCenter({
    map: map,
    lat: location.lat,
    lng: location.lng
  });

  return useFitToRadius(radius, map);
};

export default useResetUserLocation;

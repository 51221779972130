export default function useGetUserLocation(store) {
  const location = {
    lat: -15.758652378178803,
    lng: -47.84569142284889,
    zoom: 8
  };

  return new Promise(resolve => {
    if (navigator.geolocation) {
      store.dispatch("preloader/SET_LOADING");

      navigator.geolocation.getCurrentPosition(
        position => {
          location.lat = position.coords.latitude;
          location.lng = position.coords.longitude;
          location.zoom = 17;

          store.dispatch("preloader/UNSET_LOADING");
          resolve(location);
        },
        () => {
          store.dispatch("preloader/UNSET_LOADING");
          resolve(location);
        }
      );
    }
  });
}

export default {
  SET_OPEN(state) {
    state.open = true;
  },
  UNSET_OPEN(state) {
    state.open = false;
  },
  SET_VIEWMODE(state, viewMode) {
    state.viewMode = viewMode;
  }
};

<template>
  <div>
    <teleport to="body">
      <div class="modal" :class="{ 'is-active': active }">
        <div class="modal-background" @click="onClose"></div>
        <div class="modal-content">
          <slot />
        </div>
      </div>
    </teleport>
  </div>
</template>

<script>
export default {
  name: "MModal",
  props: {
    active: {
      type: Boolean,
      required: true,
      default: false
    },
    onClose: {
      default: () => {},
      required: true,
      type: Function
    }
  }
};
</script>

<style lang="scss">
@import "~bulma/sass/components/modal.sass";
</style>

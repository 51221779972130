import { computed } from "@vue/runtime-core";
import useCreateMarker from "./useCreateMarker";
import useInfoTemplate from "./useInfoTemplate";
import useWaitForGoogle from "./useWaitForGoogle";

export default async function usePlaceMarkers({
  addMarker,
  emptyMarkers,
  fitBounds,
  map,
  places,
  store,
  t
}) {
  await useWaitForGoogle();

  const infoWindow = new google.maps.InfoWindow();

  emptyMarkers(["user-location"]);

  places.forEach(place => {
    const [{ lat, lng }] = place.coordinates;

    addMarker(
      useCreateMarker({
        id: place.id,
        markerOptions: {
          animation: google.maps.Animation.DROP,
          icon: place.icon,
          map: map,
          position: { lat: +lat, lng: +lng },
          title: place.name
        },
        onClick(marker) {
          infoWindow.setContent(useInfoTemplate(place, t));
          infoWindow.setOptions({ maxWidth: 280 });
          infoWindow.open(map, marker.instance);
        }
      })
    );
  });

  if (fitBounds) {
    const bounds = computed(() => store.getters["markers/bounds"]);

    store.dispatch("markers/SET_BOUNDS", new google.maps.LatLngBounds());

    places.forEach(({ coordinates: [{ lat, lng }] }) =>
      store.dispatch(
        "markers/EXTEND_BOUNDS",
        new google.maps.LatLng(+lat, +lng)
      )
    );

    google.maps.event.addListenerOnce(map, "bounds_changed", function() {
      this.setZoom(Math.min(15, this.getZoom()));
    });

    setTimeout(() => map.fitBounds(bounds.value), 1000);
  }
}

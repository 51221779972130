<template>
  <THome />
</template>

<script>
import THome from "@/components/templates/Home.vue";

export default {
  components: {
    THome
  },
  name: "PHome"
};
</script>

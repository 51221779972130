<template>
  <div class="m-notification">
    <slot />
    <MModal v-if="messages.length" :active="Boolean(messages.length)">
      <article
        v-for="message in messages"
        class="message"
        :class="message.type"
        :key="message.id"
      >
        <div class="message-header">
          <p v-if="message.type === 'is-info'">{{ $t("Aviso") }}</p>
          <p v-else-if="message.type === 'is-danger'">{{ $t("Erro") }}</p>
          <p v-else>{{ $t("Sucesso") }}</p>
          <button
            class="delete"
            aria-label="delete"
            @click.prevent="() => closeMessage(message.id)"
          ></button>
        </div>
        <div class="message-body">
          {{ message.message }}
        </div>
      </article>
    </MModal>
  </div>
</template>

<script>
import MModal from "./Modal.vue";
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "MNotification",
  components: {
    MModal
  },
  setup() {
    const store = useStore();
    const messages = computed(() => store.getters["notification/messages"]);

    function closeMessage(id) {
      store.dispatch("notification/UNSET_MESSAGE", id);
    }

    return {
      closeMessage,
      messages
    };
  }
};
</script>

<style lang="scss">
@import "~bulma/sass/components/message.sass";

.message {
  left: 50%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.message-header {
  .delete {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-color: rgba(10, 10, 10, 0.2);
    border: none;
    border-radius: 9999px;
    cursor: pointer;
    pointer-events: auto;
    display: inline-block;
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 0;
    height: 20px;
    max-height: 20px;
    max-width: 20px;
    min-height: 20px;
    min-width: 20px;
    outline: 0;
    position: relative;
    vertical-align: top;
    width: 20px;

    &::before,
    &::after {
      @extend %pseudo-el;
      background-color: #fff;
      display: block;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
      transform-origin: center center;
    }

    &::before {
      height: 2px;
      width: 50%;
    }

    &::after {
      height: 50%;
      width: 2px;
    }
  }
}
</style>

<template>
  <div class="m-preloader">
    <slot />
    <teleport to="body">
      <div v-if="isLoading" class="m-preloader__backdrop is-flex m-0 p-0">
        <div
          class="m-preloader__loader has-text-centered is-align-items-center is-flex is-justify-content-center"
        >
          <div class="wrapper is-flex">
            <span class="is-block"></span>
            <span class="is-block"></span>
            <span class="is-block"></span>
            <span class="is-block"></span>
            <span class="is-block"></span>
          </div>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "MPreloader",
  setup() {
    const store = useStore();
    const isLoading = computed(() => store.getters["preloader/isLoading"]);

    return {
      isLoading
    };
  }
};
</script>

<style lang="scss">
.m-preloader {
  &__backdrop {
    @extend %zero-position;
    min-height: 100vh;
    position: fixed;
    width: 100vw;
    z-index: 41;

    &::before {
      @extend %pseudo-el;
      @extend %position-absolute;
      @extend %width-full;
      @extend %zero-position;
      background-color: white;
      height: 100%;
      opacity: 0.75;
      pointer-events: none;
    }
  }

  &__loader {
    @extend %position-relative;
    @extend %width-full;
    box-shadow: 0px 40px 60px -20px rgba(0, 0, 0, 0.2);
    vertical-align: middle;

    .wrapper {
      width: auto;
    }

    span {
      background: green;
      border-radius: 50%;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
      height: 20px;
      margin: 0 5px;
      width: 20px;

      &:nth-child(2) {
        background: #ff1e00;
      }

      &:nth-child(3) {
        background: #025aea;
      }

      &:nth-child(4) {
        background: #ffffff;
      }

      &:not(:last-child) {
        animation: animate 1.5s linear infinite;
      }

      &:last-child {
        background: yellow;
        animation: jump 1.5s ease-in-out infinite;
      }
    }
  }
}

@keyframes animate {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(30px);
  }
}

@keyframes jump {
  0% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(10px, -10px);
  }
  20% {
    transform: translate(20px, 10px);
  }
  30% {
    transform: translate(30px, -50px);
  }
  70% {
    transform: translate(-150px, -50px);
  }
  80% {
    transform: translate(-140px, 10px);
  }
  90% {
    transform: translate(-130px, -10px);
  }
  100% {
    transform: translate(-120px, 0);
  }
}
</style>

<template>
  <div class="t-home is-flex is-flex-direction-column">
    <OHeader class="is-flex-shrink-0" />
    <ASection class="o-interactive-map is-flex-grow-1 pb-4 pt-4">
      <AColumns class="o-interactive-map__container">
        <AColumn class="o-interactive-map__menu is-2-desktop is-2-widescreen">
          <OMenu :items="categories" />
        </AColumn>
        <AColumn class="o-interactive-map__map pb-0">
          <slot />
        </AColumn>
      </AColumns>
    </ASection>
  </div>
</template>

<script>
import { computed, onMounted } from "vue";
import AColumn from "@/components/atoms/Column.vue";
import AColumns from "@/components/atoms/Columns.vue";
import ASection from "@/components/atoms/Section.vue";
import OHeader from "@/components/organisms/Header.vue";
import OMenu from "@/components/organisms/Menu.vue";
import { useStore } from "vuex";

export default {
  name: "TSinglePage",
  components: {
    AColumn,
    AColumns,
    OHeader,
    ASection,
    OMenu
  },
  setup() {
    const store = useStore();
    const categories = computed(() => store.getters["places/categories"]);

    function getCategories() {
      if (!categories.value.length) {
        return store.dispatch("places/GET_CATEGORIES");
      }

      return Promise.resolve();
    }

    function openContact() {
      store.dispatch("contact/SET_OPEN");
    }

    onMounted(() => {
      getCategories();
    });

    return {
      categories,
      openContact
    };
  }
};
</script>

<style lang="scss">
.o-interactive-map {
  &__menu {
    position: fixed !important;

    @include media(">=desktop") {
      position: relative !important;
    }
  }

  &__map {
    .content {
      padding-bottom: 6rem;

      @include media(">=desktop") {
        padding-bottom: 0;
      }

      p {
        line-height: 24px;
      }

      a {
        text-decoration: underline;
      }
    }
  }
}
</style>

<template>
  <AColumns
    class="m-header-tablet is-centered is-hidden-mobile is-hidden-desktop is-multiline mt-1 ml-0 mr-0 mb-1"
  >
    <AColumn class="is-3 is-align-items-center is-flex has-text-centered">
      <img
        :alt="$t('Hub Accounting')"
        class="o-header__logo mb-0"
        src="@/assets/hub-accounting-logo.webp"
      />
    </AColumn>
    <AColumn
      class="is-2 is-align-items-center is-justify-content-center is-flex"
    >
      <img
        :alt="$t('Maluly Jr. Advogados')"
        class="o-header__logo mb-0"
        src="@/assets/maluly-jr-advogados-logo.webp"
      />
    </AColumn>
    <AColumn
      class="is-4 is-align-items-center is-justify-content-center is-flex"
    >
      <img
        :alt="$t('Embaixada Eslovênia - Brasília')"
        class="o-header__logo mb-0"
        src="@/assets/logo-embaixada-eslovenia.webp"
      />
    </AColumn>
    <AColumn class="is-3 is-align-items-center is-flex has-text-centered">
      <img
        :alt="$t('Slovenian Global Business Network')"
        class="o-header__logo mr-4 mb-0"
        src="@/assets/slovenian-global-business-network-logo.webp"
      />
    </AColumn>
    <AColumn
      class="o-header__title is-align-items-center is-flex is-full is-justify-content-center is-size-4"
    >
      <h1 class="has-text-weight-bold is-uppercase">
        <span :title="$t('Negócios Eslovenos no Brasil')" to="/">
          {{ $t("Negócios Eslovenos no Brasil") }}
        </span>
      </h1>
    </AColumn>
    <AColumn class="o-header__search has-text-weight-bold is-10">
      <MSearchForm />
    </AColumn>
  </AColumns>
</template>

<script>
import AColumn from "@/components/atoms/Column.vue";
import AColumns from "@/components/atoms/Columns.vue";
import MSearchForm from "@/components/molecules/SearchForm.vue";

export default {
  name: "MHeaderTablet",
  components: {
    AColumn,
    AColumns,
    MSearchForm
  }
};
</script>

<template>
  <div class="m-map">
    <div id="map" ref="mapRef"></div>
  </div>
</template>

<script>
import { computed, inject, onMounted, ref } from "vue";
import useCreateMarker from "@/composables/useCreateMarker";
import useGetUserLocation from "@/composables/useGetUserLocation";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

export default {
  name: "OMap",
  props: {
    onLoad: {
      required: false,
      type: Function
    }
  },
  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const mapI = computed(() => store.getters["markers/map"]);
    const map = ref(null);
    const mapRef = ref(null);
    const addMarker = inject("addMarker");

    function createGMapsScript(callback) {
      if (mapI.value) {
        return callback();
      }

      const googleMapsScript = document.createElement("script");

      googleMapsScript.setAttribute(
        "src",
        `https://maps.googleapis.com/maps/api/js?key=AIzaSyCGmFjfva2ceyPeyh9DBfp8vkhVwnsTi_Q&libraries=&v=beta`
      );
      googleMapsScript.setAttribute("defer", "");
      googleMapsScript.setAttribute("async", "");
      googleMapsScript.onload = callback;

      document.head.appendChild(googleMapsScript);
    }

    async function createMap() {
      const location = await useGetUserLocation(store);

      map.value = new google.maps.Map(mapRef.value, {
        center: { lat: location.lat, lng: location.lng },
        styles: [
          {
            featureType: "poi.business",
            stylers: [
              {
                visibility: "off"
              }
            ]
          }
        ],
        zoom: location.zoom
      });

      addMarker(
        useCreateMarker({
          id: "user-location",
          markerOptions: {
            icon: require("@/assets/user-location.png"),
            map: map.value,
            position: { lat: location.lat, lng: location.lng },
            title: t("Você está aqui")
          }
        })
      );
    }

    onMounted(() => {
      createGMapsScript(async () => {
        await createMap();
        store.dispatch("preloader/UNSET_LOADING");
        props.onLoad(map.value);
      });
    });

    return {
      map,
      mapRef
    };
  }
};
</script>

<style lang="scss">
#map {
  height: 100%;
}
</style>

<template>
  <aside class="o-menu menu" :class="{ open: isOpen }" ref="container">
    <div class="o-menu__container">
      <MSearchForm class="mb-3 ml-3 mr-3 mt-2" :onSubmit="onSearchFormSubmit" />
      <template v-for="item in items" :key="item.id">
        <ul class="menu-list">
          <li>
            <router-link
              class="is-uppercase"
              :class="{
                'router-link-active': isChildrenActive(item.slug)
              }"
              :title="$t(item.name)"
              :to="`/categoria/${item.slug}`"
            >
              {{ $t(item.name) }}
            </router-link>
            <ul class="menu-list mr-0 pl-0">
              <li v-for="subItem in item.subcategories" :key="subItem.id">
                <router-link
                  :title="$t(item.name)"
                  :to="`/categoria/${item.slug}/${subItem.slug}`"
                >
                  {{ $t(subItem.name) }}
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </template>
    </div>
    <teleport to="body">
      <div
        v-if="
          !isOpen &&
            ['Categoria', 'SubCategoria', 'Results'].includes(route.name)
        "
        class="view-mode is-hidden-desktop floating-option ml-3"
      >
        <div
          class="view-mode__option is-inline-block p-3"
          :class="{ active: currentViewMode === 'list' }"
          @click="() => setViewMode('list')"
        >
          <img
            v-if="currentViewMode === 'list'"
            :alt="$t('Ver em lista')"
            src="@/assets/list-white.svg"
          />
          <img v-else :alt="$t('Ver em lista')" src="@/assets/list.svg" />
        </div>
        <div
          class="view-mode__option is-inline-block p-3"
          :class="{ active: currentViewMode === 'map' }"
          @click="() => setViewMode('map')"
        >
          <img
            v-if="currentViewMode === 'map'"
            :alt="$t('Ver no mapa')"
            src="@/assets/pin-white.svg"
          />
          <img v-else :alt="$t('Ver no mapa')" src="@/assets/pin.svg" />
        </div>
      </div>
      <div
        class="c-bottom-bar is-flex is-justify-content-space-around px-4 pb-3 is-hidden-desktop"
      >
        <button
          class="c-bottom-bar__button ml-0 mr-0"
          :class="{ 'c-bottom-bar__button--highlighted': isOpen }"
          @click="toggleMenu"
          :title="$t('Abrir menu de Categorias')"
          type="button"
        >
          <img :alt="$t('Ícone de Bússola')" src="@/assets/compass.svg" />
        </button>
        <button
          class="c-bottom-bar__button ml-0 mr-0"
          :class="{
            'c-bottom-bar__button--highlighted': $route.name === 'Home'
          }"
          @click="$router.push({ name: 'Home' })"
          :title="$t('Ir para a home do site')"
          type="button"
        >
          <img :alt="$t('Ícone de Home')" src="@/assets/home.svg" />
        </button>
        <button
          class="c-bottom-bar__button ml-0 mr-0"
          :class="{
            'c-bottom-bar__button--highlighted': isSiteMenuOpen
          }"
          @click="toggleSiteMenu"
          :title="$t('Abrir menu do site')"
          type="button"
        >
          <img :alt="$t('Ícone de Home')" src="@/assets/menu.svg" />
        </button>
      </div>
    </teleport>
  </aside>
</template>

<script>
import "@fortawesome/fontawesome-free/js/solid";
import "@fortawesome/fontawesome-free/js/fontawesome";
import { computed, inject, onMounted, ref, watch } from "vue";
import $ from "jquery";
import MSearchForm from "@/components/molecules/SearchForm.vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "OMenu",
  components: {
    MSearchForm
  },
  props: {
    items: {
      required: true,
      type: Array
    }
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const container = ref(null);
    const isOpen = computed(() => store.getters["menu/isOpen"]);
    const currentViewMode = computed(() => store.getters["menu/viewMode"]);
    const { isSiteMenuOpen, setIsSiteMenuOpen } = inject("siteMenu");

    onMounted(() => {
      $(function() {
        $(container.value).overlayScrollbars({
          scrollbars: {
            autoHide: "leave"
          }
        });
      });
    });

    function isChildrenActive(slug) {
      return (
        route.name === "SubCategoria" &&
        route.path.replace(/\/.*\/(.*)\/.*$/, "$1") === slug
      );
    }

    function toggleMenu() {
      !isOpen.value
        ? store.dispatch("menu/SET_OPEN")
        : store.dispatch("menu/UNSET_OPEN");
    }

    function toggleSiteMenu() {
      setIsSiteMenuOpen(!isSiteMenuOpen.value);
    }

    function setViewMode(viewMode) {
      if (currentViewMode.value !== viewMode) {
        store.dispatch("menu/SET_VIEWMODE", viewMode);
      }
    }

    function onSearchFormSubmit() {
      toggleMenu();
    }

    watch(
      () => route.path,
      async () => {
        if (isOpen.value && ["SubCategoria", "Results"].includes(route.name)) {
          toggleMenu();
        }
      }
    );

    return {
      container,
      currentViewMode,
      isChildrenActive,
      isOpen,
      isSiteMenuOpen,
      onSearchFormSubmit,
      route,
      setViewMode,
      toggleMenu,
      toggleSiteMenu
    };
  }
};
</script>

<style lang="scss">
@import "~bulma/sass/components/menu.sass";
@import "~bulma/sass/components/navbar.sass";

.o-menu {
  &.open {
    .o-menu {
      &__container {
        bottom: 0;

        .m-footer-mobile {
          bottom: 0;
        }
      }
    }
  }

  &__container {
    @extend %transition-default;
    @extend %width-full;
    background-color: white;
    box-shadow: 0 -2px 6px rgba($color: #000000, $alpha: 0.08);
    left: 0;
    height: 76vh;
    overflow-y: auto;
    padding-top: 0.5rem;
    padding-bottom: 4.5rem;
    position: fixed;
    bottom: -100%;
    z-index: 11;

    @include media(">=tablet") {
      .m-search-form {
        display: none;
      }
    }

    @include media(">=desktop") {
      padding-top: 0;

      .m-search-form {
        display: block;
        margin-top: 0 !important;
      }
    }

    .menu-list {
      .menu-list {
        height: 0;
        margin: 0;
        overflow: hidden;
      }

      .router-link-active + .menu-list {
        height: auto;
        margin: 0.75rem;
        overflow: auto;
      }

      .router-link-exact-active {
        border-bottom: 1px solid #dbdbdb;
        border-top: 1px solid #dbdbdb;
        box-shadow: 0 0 6px rgba($color: #000000, $alpha: 0.1);
      }
    }

    .m-footer-mobile {
      @extend %transition-default;
      bottom: -100%;

      @include media(">=desktop") {
        display: none;
      }
    }
  }

  &__info {
    bottom: 3vh;
    position: absolute;
  }
}

.c-bottom-bar {
  bottom: 0;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 12;

  &__button {
    background-color: white !important;
    border: 1px solid transparent;
    border-radius: 50%;
    box-shadow: 0 0 6px rgba($color: #000000, $alpha: 0.1);
    height: 48px;
    width: 48px;
    position: relative;

    &--highlighted {
      border-color: rgba($color: $regal-blue, $alpha: 0.5);
    }
  }
}

.view-mode {
  border: 1px solid $regal-blue;
  border-radius: 8px;
  background-color: #fff !important;
  bottom: 62px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  position: fixed;
  right: 12px;
  z-index: 13;

  img {
    height: 22px;
    width: 22px;
  }

  &__option {
    width: 50%;

    &.active {
      background-color: $regal-blue;
    }
  }
}

@include media(">=desktop") {
  .o-menu {
    &__container {
      border-top: 0;
      bottom: initial;
      height: auto;
      position: relative;
    }
  }
}
</style>

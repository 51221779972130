<template>
  <SinglePage>
    <h2 class="has-text-centered is-size-5 mb-5">
      {{ $t("Escolha um país") }}
    </h2>
    <AColumns
      class="p-sponsors is-multiline is-mobile is-centered has-text-centered"
    >
      <AColumn class="p-sponsors__item is-full-mobile is-half-tablet mb-4">
        <a
          href="https://argentina.slovenianbusinessmap.com/"
          :title="$t('Argentina')"
        >
          <img src="@/assets/countries/argentina.jpg" :alt="$t('Argentina')" />
        </a>
      </AColumn>
      <AColumn class="p-sponsors__item is-full-mobile is-half-tablet">
        <router-link :title="$t('Brasil')" to="/">
          <img src="@/assets/countries/brazil.jpg" :alt="$t('Brasil')" />
        </router-link>
      </AColumn>
    </AColumns>
  </SinglePage>
</template>

<script>
import AColumn from "@/components/atoms/Column.vue";
import AColumns from "@/components/atoms/Columns.vue";
import SinglePage from "@/components/templates/SinglePage.vue";

export default {
  components: {
    AColumns,
    AColumn,
    SinglePage
  },
  name: "PCountries"
};
</script>

<style lang="scss" scoped>
h2 {
  color: $regal-blue;

  @include media(">=tablet") {
    margin-top: 1.4rem;
  }
}

img {
  display: block;
  margin: 0 auto;
  max-width: 180px;
}
</style>

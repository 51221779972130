import EMPTY_MARKERS from "./emptyMarkers";
import EXTEND_BOUNDS from "./extendBounds";
import REMOVE_MARKER from "./removeMarker";
import SET_BOUNDS from "./setBounds";
import SET_MAP from "./setMap";
import SET_MARKERS from "./setMarkers";

export default {
  EMPTY_MARKERS,
  EXTEND_BOUNDS,
  REMOVE_MARKER,
  SET_BOUNDS,
  SET_MAP,
  SET_MARKERS
};

<template>
  <component
    class="m-place pt-2"
    :class="{ 'm-place--open': open, 'm-place--selected': selected }"
    :is="tag"
    @click.capture="() => onSelect(data.id)"
  >
    <a
      @click.prevent="toggle"
      href=":javascript;"
      class="m-place__more"
      title="Veja mais"
    >
      <span class="icons" :class="{ 'is-hidden': open }">
        <i class="fas fa-plus-square fa-lg"></i>
      </span>
      <span class="icons" :class="{ 'is-hidden': !open }">
        <i class="fas fa-minus-square fa-lg"></i>
      </span>
    </a>
    <AContent class="is-size-7">
      <h4 class="is-size-6 mb-2">{{ data.name }}</h4>
      <div class="m-place__summary mb-2">
        <div v-if="data.field_of_action" class="mb-1">
          - {{ $t(data.field_of_action) }}
        </div>
        <div v-if="data.products_services" class="mb-1">
          - {{ $t(data.products_services) }}
        </div>
      </div>
      <div class="m-place__details">
        <address class="mb-3">
          <p v-if="data.address" class="mb-1">{{ data.address }}</p>
          <p>
            <span v-if="data.city">{{ data.city }}</span> -
            <span>{{ data.region.uf }}</span> -
            <span v-if="data.postcode">CEP {{ data.postcode }}</span>
          </p>
        </address>
        <p class="m-place__phone" v-if="data.phone">
          <a
            class="is-block mb-1"
            :href="`tel:${data.phone}`"
            target="_blank"
            :title="`${$t('Ligar para')} ${data.name}`"
            >{{ data.phone }}</a
          >
        </p>
        <p class="m-place__website" v-if="data.website">
          <a
            class="is-block mb-1"
            :href="formatUrl(data.website)"
            rel="noopener"
            target="_blank"
            :title="data.name"
            >{{ data.website.replace(/^https?:\/\/(.*)?\//, "$1") }}</a
          >
        </p>
        <p class="m-place__email" v-if="data.email">
          <a
            class="is-block mb-1"
            :href="`mailto:${data.email}`"
            rel="noopener"
            target="_blank"
            :title="$t('Enviar e-mail')"
            >{{ data.email }}</a
          >
        </p>
        <p class="m-place__social">
          <a
            v-if="data.facebook"
            class="is-inline-block mr-2"
            :href="data.facebook"
            rel="noopener"
            target="_blank"
            title="Facebook"
          >
            <span class="icons">
              <i class="fa-facebook-square fab fa-lg"></i>
            </span>
          </a>
          <a
            v-if="data.instagram"
            class="is-inline-block mr-2"
            :href="data.instagram"
            rel="noopener"
            target="_blank"
            title="Instagram"
          >
            <span class="icons">
              <i class="fa-instagram-square fab fa-lg"></i>
            </span>
          </a>
          <a
            v-if="data.linkedin"
            class="is-inline-block mr-2"
            :href="data.linkedin"
            rel="noopener"
            target="_blank"
            title="Linkedin"
          >
            <span class="icons">
              <i class="fa-linkedin fab fa-lg"></i>
            </span>
          </a>
        </p>
        <p class="mb-3">
          <a
            @click.prevent="onMoreInfo"
            href=":javascript:;"
            :title="`${$t('Maiores informações sobre')} ${data.name}`"
            >{{ $t("Mais informações") }}</a
          >
        </p>
      </div>
    </AContent>
  </component>
</template>

<script>
import "@fortawesome/fontawesome-free/js/solid";
import "@fortawesome/fontawesome-free/js/fontawesome";
import AColumn from "@/components/atoms/Column.vue";
import AColumns from "@/components/atoms/Columns.vue";
import AContent from "@/components/atoms/Content.vue";
import { formatWebsiteUrl } from "@/helpers/utils";
import { ref } from "vue";

export default {
  name: "MPlace",
  components: {
    AColumn,
    AColumns,
    AContent
  },
  props: {
    data: {
      required: true,
      type: Object
    },
    onMoreInfo: {
      default: () => {},
      required: true,
      type: Function
    },
    onSelect: {
      default: () => {},
      required: false,
      type: Function
    },
    selected: {
      default: false,
      required: false,
      type: Boolean
    },
    tag: {
      default: "div",
      required: false,
      type: String
    }
  },
  setup() {
    const open = ref(false);

    function toggle() {
      open.value = !open.value;
    }

    const formatUrl = url => formatWebsiteUrl(url);

    return {
      formatUrl,
      open,
      toggle
    };
  }
};
</script>

<style lang="scss">
@import "~bulma/sass/form/all.sass";

$m-place: ".m-place";

#{$m-place} {
  color: #777777;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: #eee;
  }

  &--open {
    #{$m-place} {
      &__details {
        height: auto;
        max-height: 5000px;
        transition: all 0.2s linear;
      }
    }
  }

  &--selected {
    background-color: rgb(204, 228, 255);
  }

  address {
    font-style: normal;
  }

  h4 {
    color: $regal-blue;
    width: calc(100% - 20px);
  }

  &__more {
    background-color: white;
    color: $regal-blue;
    position: absolute;
    right: 0.75rem;
    top: 0.35rem;
  }

  &__details {
    height: 0;
    max-height: 0;
    overflow: hidden;
    transition: all 0.2s linear;
  }

  .c-newsletter {
    border-top: 1px dashed #777777;
    border-bottom: 1px dashed #777777;
  }
}
</style>

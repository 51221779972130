<template>
  <section class="section">
    <slot />
  </section>
</template>

<script>
export default {
  name: "ASection"
};
</script>

<style lang="scss">
@import "~bulma/sass/layout/section.sass";
</style>

<template>
  <div class="o-markers">
    <slot />
  </div>
</template>

<script>
import { provide } from "vue";
import useWaitForGoogle from "@/composables/useWaitForGoogle";

export default {
  name: "OMarkers",
  setup() {
    let infoWindow;

    useWaitForGoogle().then(() => {
      infoWindow = new google.maps.InfoWindow();
    });

    let markers = [];

    const addMarker = marker =>
      !markers.find(m => m.id === marker.id) ? markers.push(marker) : "";

    const emptyMarkers = (whiteList = []) => {
      const keepList = [
        ...markers.filter(marker => whiteList.includes(marker.id))
      ];

      for (let index = 0; index < markers.length; index++) {
        if (!whiteList.includes(markers[index].id)) {
          markers[index].instance.setMap(null);
        }
      }

      markers = keepList;
    };

    const getMarker = id => markers.find(marker => marker.id === id);

    const removeMarker = id => {
      const markerIndex = markers.findIndex(marker => marker.id === id);

      if (markerIndex >= 0) {
        markers[markerIndex].instance.setMap(null);
        markers.splice(markerIndex, 1);
      }
    };

    function getInfoWindow() {
      return infoWindow;
    }

    provide("infoWindow", infoWindow);
    provide("markers", markers);
    provide("addMarker", addMarker);
    provide("emptyMarkers", emptyMarkers);
    provide("getInfoWindow", getInfoWindow);
    provide("getMarker", getMarker);
    provide("removeMarker", removeMarker);
  }
};
</script>

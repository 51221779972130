const useFitToRadius = (radius, map) => {
  const circle = new google.maps.Circle({
    center: map.getCenter(),
    fillOpacity: 0,
    strokeOpacity: 0,
    map: map,
    radius: radius * 1000
  });

  map.fitBounds(circle.getBounds());

  return Promise.resolve();
};

export default useFitToRadius;

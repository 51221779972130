import "./registerServiceWorker";
import "overlayscrollbars/js/jquery.overlayScrollbars";
import App from "./App.vue";
import api from "@/helpers/api";
import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import router from "./router";
import store from "./store";

(async () => {
  const messages = await api.get("v1/locales");

  const i18n = createI18n({
    locale: "pt-br",
    fallbackLocale: "en",
    messages: messages.data.data
  });

  createApp(App)
    .use(store)
    .use(i18n)
    .use(router)
    .mount("#app");
})();

import GET_CATEGORIES from "./getCategories";
import GET_PLACES from "./getPlaces";
import SET_CATEGORIES from "./setCategories";
import SET_PLACES from "./setPlaces";
import SET_SELECTED from "./setSelected";
import UNSET_SELECTED from "./unsetSelected";

export default {
  GET_CATEGORIES,
  GET_PLACES,
  SET_CATEGORIES,
  SET_PLACES,
  SET_SELECTED,
  UNSET_SELECTED
};

export default {
  SET_CATEGORIES(state, categories) {
    state.categories = categories;
  },
  SET_PLACES(state, places) {
    state.list = places;
    state.loaded = true;
  },
  SET_SELECTED(state, placeId) {
    state.selected = placeId;
  },
  UNSET_SELECTED(state) {
    state.selected = 0;
  }
};

<template>
  <ul
    :class="{ visible: !isMenuOpen && viewMode === 'list' }"
    class="o-places mb-5"
    ref="container"
  >
    <span v-if="placesList.length">
      <OMoreInfo
        v-if="placeId"
        :onClose="closeForm"
        :open="Boolean(placeId)"
        :placeId="placeId"
      />
      <MPlace
        v-for="place in placesList"
        class="o-places__place mb-0 pl-3 pr-3"
        :data="place"
        :key="place.id"
        :onMoreInfo="() => setPlaceId(place.id)"
        :onSelect="onSelectPlace"
        :selected="selectedPlace === place.id"
        tag="li"
      />
    </span>
    <li
      v-if="placesLoaded && !placesList.length"
      class="m-place o-places__place mb-4 ml-4"
    >
      <p>
        {{ $t("Nenhum negócio encontrado.") }}
      </p>
      <p>
        {{ $t("Para mais informações, ") }}
        <a href="#" :title="$t('Contato')" @click.prevent="openContact">
          <strong>{{ $t("clique aqui") }}</strong>
        </a>
        {{ $t("e entre em contato.") }}
      </p>
    </li>
    <li v-if="!placesLoaded" class="m-place o-places__place mb-4 ml-4">
      <p>{{ $t("Carregando...") }}</p>
    </li>
  </ul>
</template>

<script>
import { computed, inject, onMounted, ref, watch } from "vue";
import $ from "jquery";
import MPlace from "@/components/molecules/Place.vue";
import OMoreInfo from "@/components/organisms/MoreInfo.vue";
import slugify from "slugify";
import { useI18n } from "vue-i18n";
import useInfoTemplate from "@/composables/useInfoTemplate";
import usePlaceMarkers from "@/composables/usePlaceMarkers";
import { useRoute } from "vue-router";
import useSetMapCenter from "@/composables/useSetMapCenter";
import useShowPlaces from "@/composables/useShowPlaces";
import { useStore } from "vuex";
import useWaitForGoogle from "@/composables/useWaitForGoogle";

export default {
  components: {
    MPlace,
    OMoreInfo
  },
  name: "Places",
  props: {
    cat: {
      required: false,
      type: String
    },
    subcat: {
      required: false,
      type: String
    }
  },
  setup(props) {
    const { t } = useI18n();
    const container = ref(null);
    const placeId = ref(0);
    const store = useStore();
    const route = useRoute();
    const placesLoaded = computed(() => store.getters["places/loaded"]);
    const map = computed(() => store.getters["markers/map"]);
    const selectedPlace = computed(() => store.getters["places/selected"]);
    const places = computed(() => store.getters["places/places"]);
    const isMenuOpen = computed(() => store.getters["menu/isOpen"]);
    const viewMode = computed(() => store.getters["menu/viewMode"]);
    const placesList = ref([]);

    const addMarker = inject("addMarker");
    const emptyMarkers = inject("emptyMarkers");
    const getMarker = inject("getMarker");
    const removeMarker = inject("removeMarker");

    const handleMarkers = () => {
      if (useShowPlaces({ route }) && placesList.value.length) {
        removeMarker("user-location");
        usePlaceMarkers({
          addMarker,
          emptyMarkers,
          fitBounds: true,
          map: map.value,
          places: getFilteredPlaces(route),
          store,
          t
        });
      }
    };

    const getFilteredPlaces = rt => {
      const filtered = places.value.filter(item => {
        if (rt.query.q) {
          return filterByQuery(item);
        }

        return filterByCategory(item);
      });

      return filtered;
    };

    function filterByQuery(item) {
      const q = new RegExp(slugify(route.query.q), "gi");

      if (slugify(item.name).match(q)) {
        return true;
      }

      if (
        t(item.category.slug).match(q) ||
        item.category.slug.match(q) ||
        slugify(t(item.category.name)).match(q) ||
        slugify(item.category.name).match(q) ||
        slugify(t(item.subcategory.slug)).match(q) ||
        slugify(item.subcategory.slug).match(q) ||
        slugify(t(item.subcategory.name)).match(q) ||
        slugify(item.subcategory.name).match(q)
      ) {
        return true;
      }

      if (
        item.field_of_action &&
        (slugify(t(item.field_of_action)).match(q) ||
          slugify(item.field_of_action).match(q))
      ) {
        return true;
      }

      if (
        item.products_services &&
        (slugify(t(item.products_services)).match(q) ||
          slugify(item.products_services).match(q))
      ) {
        return true;
      }

      if (item.city && slugify(item.city).match(q)) {
        return true;
      }

      if (
        item.description &&
        (slugify(t(item.description)).match(q) ||
          slugify(item.description).match(q))
      ) {
        return true;
      }

      if (
        (item.region.name && slugify(item.region.name).match(q)) ||
        (item.region.uf && item.region.uf.match(q))
      ) {
        return true;
      }

      return false;
    }

    function filterByCategory(item) {
      let status = false;
      const category = item.category.slug === props.cat;

      if (category) {
        status = true;
      }

      if (props.subcat) {
        const subcategory = item.subcategory.slug === props.subcat;

        if (!subcategory) {
          status = false;
        }
      }

      return status;
    }

    const onSelectPlace = async id => {
      if (selectedPlace.value === id) {
        return;
      }

      const marker = getMarker(id);
      const [place] = placesList.value.filter(p => p.id === id);

      if (marker && place) {
        const { lat, lng } = marker.instance.getPosition();

        await useSetMapCenter({ lat: lat(), lng: lng(), map: map.value });

        setTimeout(async () => {
          await useWaitForGoogle();

          const infoWindow = new google.maps.InfoWindow();

          map.value.setZoom(15);

          await useSetMapCenter({ lat: lat(), lng: lng(), map: map.value });

          infoWindow.setContent(useInfoTemplate(place, t));
          infoWindow.setOptions({ maxWidth: 280 });
          infoWindow.open(map.value, marker.instance);
        }, 1000);
      }

      store.dispatch("places/SET_SELECTED", id);
    };

    function setPlaceId(id) {
      placeId.value = id;
    }

    function closeForm() {
      placeId.value = 0;
    }

    function openContact() {
      store.dispatch("contact/SET_OPEN");
    }

    onMounted(() => {
      placesList.value = getFilteredPlaces(route);

      handleMarkers();

      $(function() {
        $(container.value).overlayScrollbars({
          scrollbars: {
            autoHide: "leave"
          }
        });
      });
    });

    watch(
      () => [props.cat, props.subcat],
      async (value, oldValue) => {
        if (JSON.stringify(value) !== JSON.stringify(oldValue)) {
          placesList.value = getFilteredPlaces(route);
          handleMarkers();
        }
      }
    );

    watch(
      () => route.query,
      async (value, oldValue) => {
        if (JSON.stringify(value) !== JSON.stringify(oldValue)) {
          placesList.value = getFilteredPlaces(route);
          handleMarkers();
        }
      }
    );

    watch([places], () => {
      placesList.value = getFilteredPlaces(route);
      handleMarkers();
    });

    return {
      closeForm,
      container,
      isMenuOpen,
      openContact,
      onSelectPlace,
      placeId,
      placesList,
      placesLoaded,
      selectedPlace,
      setPlaceId,
      viewMode
    };
  }
};
</script>

<style lang="scss">
.o-places {
  @extend %transition-default;
  background-color: white;
  height: 100vh;
  position: fixed;
  right: -100%;
  width: 100vw;

  @include media(">=desktop") {
    background-color: transparent;
    height: 100%;
    position: initial;
    right: initial;
    width: auto;
  }

  &.visible {
    right: 0;
  }

  &__place {
    &:nth-last-of-type(1) {
      padding-bottom: 14rem;

      @include media(">=desktop") {
        padding-bottom: 0;
      }
    }
  }
}
</style>

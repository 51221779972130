export default {
  EXTEND_BOUNDS(state, latLng) {
    state.bounds = state.bounds.extend(latLng);
  },
  SET_BOUNDS(state, bounds) {
    state.bounds = bounds;
  },
  SET_MAP(state, map) {
    state.map = map;
  },
  EMPTY_MARKERS(state) {
    state.list = [];
  },
  SET_MARKERS(state, markers) {
    const newMarkersList = [];

    markers.forEach(marker => {
      if (!state.list.find(item => item.id === marker.id)) {
        newMarkersList.push(marker);
      }
    });

    state.list = [...state.list, ...newMarkersList];
  },
  REMOVE_MARKER(state, id) {
    const newMarkersList = state.list.filter(marker => marker.id !== id);
    const [markerToRemove] = state.list.filter(marker => marker.id === id);

    if (markerToRemove) {
      markerToRemove.instance.setMap(null);
    }

    state.list = [...newMarkersList];
  }
};

<template>
  <header class="o-header">
    <AColumns
      class="is-centered is-gapless is-mobile is-multiline is-vcentered"
    >
      <AColumn class="o-header__bottom is-full">
        <MHeaderMobile />
        <MHeaderTablet />
        <MHeaderDesktop />
      </AColumn>
    </AColumns>
  </header>
</template>

<script>
import AColumn from "@/components/atoms/Column.vue";
import AColumns from "@/components/atoms/Columns.vue";
import MHeaderDesktop from "@/components/molecules/HeaderDesktop.vue";
import MHeaderMobile from "@/components/molecules/HeaderMobile.vue";
import MHeaderTablet from "@/components/molecules/HeaderTablet.vue";

export default {
  name: "OHeader",
  components: {
    AColumn,
    AColumns,
    MHeaderDesktop,
    MHeaderMobile,
    MHeaderTablet
  },
  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale;
    }
  }
};
</script>

<style lang="scss">
.o-header {
  &__logo {
    max-width: 100%;
    width: 100%;
  }

  &__title {
    color: $regal-blue;
    margin-top: 4px !important;
  }

  &__languages {
    img {
      cursor: pointer;
      vertical-align: top;
      width: 22px;
    }
  }

  &__text {
    color: #777777;
  }

  &__top {
    background-color: #104873;
    color: white;
    font-size: pxToEm(9.6px);
    min-height: 30px;
  }

  &__bottom {
    background-color: white;
    box-shadow: 0 2px 6px rgba($color: #000000, $alpha: 0.08);

    & > div {
      justify-content: center;
    }
  }
}

@include media(">=phone-wide") {
  .o-header {
    &__logo {
      max-width: 240px;
    }
  }
}

@include media(">=tablet") {
  .o-header {
    &__logo {
      margin-bottom: 4px !important;
      margin-top: 0 !important;
      max-width: 240px;
    }

    &__top {
      font-size: 12px;
    }

    &__text {
      margin-bottom: 0 !important;
      margin-left: 12px !important;
    }

    &__title {
      margin-top: 0 !important;
    }
  }
}

@include media(">=desktop") {
  .o-header {
    &__title {
      max-width: 188px;
    }

    &__logo {
      max-height: 52px;
      max-width: 100%;
      width: auto;
    }

    &__bottom {
      & > div {
        padding-bottom: 0;
        padding-top: 0;
      }
    }
  }
}

@include media(">=widescreen") {
  .o-header {
    &__title {
      max-width: 214px;
    }
  }
}

@include media(">=fullhd") {
  .o-header {
    &__title {
      max-width: 340px;
    }
  }
}
</style>

<template>
  <div class="content">
    <slot />
  </div>
</template>

<script>
export default {
  name: "AContent"
};
</script>

<style lang="scss">
@import "~bulma/sass/elements/content.sass";

.content {
  h2 {
    font-size: pxToEm(20px);
    margin-bottom: pxToEm(20px);

    @include media(">=desktop") {
      font-size: pxToEm(18px);
    }
  }

  p {
    font-size: pxToEm(16px);

    @include media(">=desktop") {
      font-size: pxToEm(14px);
    }
  }

  img {
    max-width: 100%;

    @include media(">=desktop") {
      max-width: 75%;
    }
  }
}
</style>

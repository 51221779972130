<template>
  <div class="o-contact-form">
    <slot />
    <MModal v-if="open" :active="open" :onClose="onClose">
      <div class="card">
        <header class="card-header">
          <p class="card-header-title">
            {{ $t("Contato") }}
          </p>
          <button
            class="card-header-icon"
            aria-label="more options"
            @click.prevent="onClose"
          >
            <span class="icon">
              <i class="fas fa-times fa-lg" aria-hidden="true"></i>
            </span>
          </button>
        </header>
        <div class="card-content">
          <div class="content">
            <AColumns class="c-contact is-multiline mt-5 mb-3">
              <AColumn class="is-full">
                <form @submit.prevent="sendMessage">
                  <AColumns class="c-contact__form is-multiline">
                    <AColumn class="is-full">
                      <div class="field">
                        <div class="control">
                          <input
                            id="contactName"
                            class="input"
                            :class="{
                              'is-danger':
                                vl.contactName.$error && vl.contactName.$invalid
                            }"
                            name="contactName"
                            type="text"
                            :placeholder="$t('Nome')"
                            v-model="contactName"
                            required
                          />
                        </div>
                        <p
                          v-if="
                            vl.contactName.$error &&
                              vl.contactName.required.$invalid
                          "
                          class="help is-danger"
                        >
                          {{ $t("Campo obrigatório.") }}
                        </p>
                      </div>
                      <div class="field">
                        <div class="control has-icons-left has-icons-right">
                          <input
                            id="contactEmail"
                            class="input"
                            :class="{
                              'is-danger':
                                vl.contactEmail.$error &&
                                vl.contactEmail.$invalid
                            }"
                            name="contactEmail"
                            type="email"
                            :placeholder="$t('Email')"
                            v-model="contactEmail"
                            required
                          />
                          <span class="icon is-small is-left">
                            <i class="fas fa-envelope"></i>
                          </span>
                        </div>
                        <p
                          v-if="
                            vl.contactName.$error &&
                              vl.contactEmail.required.$invalid
                          "
                          class="help is-danger"
                        >
                          {{ $t("Campo obrigatório.") }}
                        </p>
                        <p
                          v-if="
                            vl.contactName.$error &&
                              vl.contactEmail.email.$invalid
                          "
                          class="help is-danger"
                        >
                          {{ $t("Forneça um email válido.") }}
                        </p>
                      </div>
                      <div class="field">
                        <div class="control has-icons-left has-icons-right">
                          <textarea
                            id="contactMessage"
                            class="textarea"
                            :class="{
                              'is-danger':
                                vl.contactMessage.$error &&
                                vl.contactMessage.$invalid
                            }"
                            name="contactMessage"
                            :placeholder="$t('Mensagem')"
                            v-model="contactMessage"
                            required
                          ></textarea>
                        </div>
                        <p
                          v-if="
                            vl.contactName.$error &&
                              vl.contactMessage.required.$invalid
                          "
                          class="help is-danger"
                        >
                          {{ $t("Campo obrigatório.") }}
                        </p>
                      </div>
                      <div class="field">
                        <div class="control">
                          <div class="buttons">
                            <button class="button is-success" type="submit">
                              {{ $t("Enviar") }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </AColumn>
                  </AColumns>
                </form>
              </AColumn>
            </AColumns>
          </div>
        </div>
      </div>
    </MModal>
  </div>
</template>

<script>
import { email, required } from "@vuelidate/validators";
import AColumn from "@/components/atoms/Column.vue";
import AColumns from "@/components/atoms/Columns.vue";
import MModal from "@/components/molecules/Modal.vue";
import api from "@/helpers/api";
import { getRecaptchaToken } from "@/helpers/utils";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";

export default {
  name: "OContactForm",
  components: {
    AColumn,
    AColumns,
    MModal
  },
  props: {
    open: {
      type: Boolean,
      required: false,
      default: false
    },
    onClose: {
      default: () => {},
      required: true,
      type: Function
    }
  },
  setup(props) {
    const store = useStore();
    const contactName = ref("");
    const contactEmail = ref("");
    const contactMessage = ref("");
    const { t } = useI18n();
    const rules = {
      contactName: { required },
      contactEmail: { required, email },
      contactMessage: { required }
    };
    const vl = useVuelidate(rules, {
      contactName,
      contactEmail,
      contactMessage
    });

    const sendMessage = async () => {
      vl.value.$touch();

      if (!vl.value.$error) {
        try {
          const token = await getRecaptchaToken(store);

          await api.post("v1/contact", {
            contactEmail: contactEmail.value,
            contactName: contactName.value,
            contactMessage: contactMessage.value,
            rcptchToken: token
          });

          store.dispatch("notification/SET_MESSAGE", {
            message: t("Sua mensagem foi enviada."),
            type: "is-success"
          });

          props.onClose();
        } catch (error) {
          store.dispatch("preloader/UNSET_LOADING");
          store.dispatch("notification/SET_MESSAGE", {
            message: t("Ocorreu um problema na solicitação."),
            type: "is-danger"
          });
          console.error(error);
        }
      }
    };

    return {
      contactName,
      contactEmail,
      contactMessage,
      sendMessage,
      t,
      vl
    };
  }
};
</script>

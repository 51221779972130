import { v1 as uuidv1 } from "uuid";

export default {
  SET_MESSAGE(state, message) {
    message.id = uuidv1();
    state.messages = state.messages.concat(message);
  },
  UNSET_MESSAGE(state, id) {
    const index = state.messages.findIndex(item => item.id === id);
    const newState = state.messages;

    newState.splice(index, 1);

    state.messages = newState;
  }
};

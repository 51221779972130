import { load } from "recaptcha-v3";

export function formatWebsiteUrl(url) {
  let link = url;

  if (!url.match(/^https?:\/\//)) {
    link = `http://${link}`;
  }

  return link;
}

export const getRecaptchaToken = async store => {
  store.dispatch("preloader/SET_LOADING");

  const recaptcha = await load(process.env.VUE_APP_RECAPTCHA);
  const token = await recaptcha.execute("contato");

  store.dispatch("preloader/UNSET_LOADING");

  return token;
};

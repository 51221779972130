<template>
  <AColumns
    class="m-header-mobile is-gapless is-hidden-tablet is-hidden-desktop is-mobile is-multiline mb-1"
  >
    <AColumn class="o-header__title is-full">
      <div class="pb-2 pt-2 pl-2">
        <h1 class="has-text-weight-bold is-uppercase has-text-centered">
          {{ $t("Negócios Eslovenos no Brasil") }}
        </h1>
      </div>
    </AColumn>
    <AColumn class="o-header__search has-text-weight-bold is-10">
      <MSearchForm class="mb-2" />
    </AColumn>
  </AColumns>
</template>

<script>
import AColumn from "@/components/atoms/Column.vue";
import AColumns from "@/components/atoms/Columns.vue";
import MSearchForm from "@/components/molecules/SearchForm.vue";

export default {
  name: "MHeaderMobile",
  components: {
    AColumn,
    AColumns,
    MSearchForm
  }
};
</script>

<style lang="scss">
.m-header-mobile {
  h1 {
    font-size: pxToEm(14px);

    @include media(">=375px") {
      font-size: pxToEm(18px);
    }

    @include media(">=425px") {
      font-size: pxToEm(18px);
    }
  }
}
</style>

export default function useCreateMarker({ id, markerOptions, onClick }) {
  const marker = {
    id,
    instance: new google.maps.Marker(markerOptions)
  };

  if (onClick) {
    marker.instance.addListener("click", () => onClick(marker));
  }

  return marker;
}

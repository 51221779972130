import contact from "./contact";
import markers from "./markers";
import menu from "./menu";
import notification from "./notification";
import pages from "./pages";
import places from "./places";
import preloader from "./preloader";

export default {
  contact,
  markers,
  menu,
  notification,
  pages,
  places,
  preloader
};

import { formatWebsiteUrl } from "@/helpers/utils";

export default function useInfoTemplate(
  {
    address,
    city,
    email,
    facebook,
    field_of_action,
    instagram,
    linkedin,
    name,
    phone,
    postcode,
    products_services,
    region,
    website
  },
  t
) {
  let completeAddress = "";
  let emailLine = "";
  let fieldOfAction = "";
  let phones = "";
  let productsServices = "";
  let site = "";
  let social = "";

  if (email) {
    emailLine = `
          <div class="c-info-window__address mb-2 mt-2">
            <a href="mailto:${email}" rel="noopener" target="_blank">
              ${email}
            </a>
          </div>
        `;
  }

  if (city && postcode) {
    completeAddress += `<div class="c-info-window__complete-address mb-2">${city} - ${region.uf} - CEP ${postcode}</div>`;
  }

  if (field_of_action) {
    fieldOfAction += `<div class="c-info-window__field_of_action">- ${t(
      field_of_action
    )}</div>`;
  }

  if (products_services) {
    productsServices += `<div class="c-info-window__products_services">- ${t(
      products_services
    )}</div>`;
  }

  if (phone) {
    phones += `<div class="c-info-window__phone">${phone}</div>`;
  }

  if (facebook) {
    social += `<span class="icons mr-2">
                <a href="${facebook}" rel="noopener noreferrer" target="_blank">
                  <i class="fab fa-facebook-square fa-lg"></i>
                </a>
              </span>`;
  }

  if (instagram) {
    social += `<span class="icons mr-2">
                <a href="${instagram}" rel="noopener noreferrer" target="_blank">
                  <i class="fab fa-instagram-square fa-lg"></i>
                </a>
              </span>`;
  }

  if (linkedin) {
    social += `<span class="icons mr-2">
                <a href="${linkedin}" rel="noopener noreferrer" target="_blank">
                  <i class="fab fa-linkedin fa-lg"></i>
                </a>
              </span>`;
  }

  if (website) {
    const link = formatWebsiteUrl(website);

    site = `
          <div class="c-info-window__address mb-2 mt-2">
            <a href="${link}" rel="noopener noreferrer" target="_blank">
              ${website.replace(/^https?:\/\/(.*)?\//, "$1")}
            </a>
          </div>
        `;
  }

  return `
        <div class="c-info-window">
          <h1 class="has-text-weight-semibold is-size-6">${name}</h1>
          ${fieldOfAction}
          ${productsServices}
          <div class="c-info-window__address mb-0 mt-2">${address}</div>
          ${completeAddress}
          ${phones}
          ${site}
          ${emailLine}
          <div class="mt-1">${social}</div>
        </div>
      `;
}

<template>
  <SinglePage>
    <h2 class="has-text-centered is-size-5 mb-5">
      {{ $t("Patrocinadores e apoiadores") }}
    </h2>
    <AColumns class="p-sponsors is-multiline is-mobile is-centered">
      <AColumn class="p-sponsors__item is-full-mobile is-half-tablet">
        <a
          class="is-block has-text-centered"
          href="https://www.maluly.adv.br/"
          target="_blank"
          rel="noopener"
          :title="$t('Maluly Jr. Advogados')"
        >
          <img
            :alt="$t('Maluly Jr. Advogados')"
            src="@/assets/sponsors/maluly-jr-advogados-logo.webp"
          />
        </a>
      </AColumn>
      <AColumn class="p-sponsors__item is-full-mobile is-half-tablet">
        <a
          class="is-block has-text-centered"
          href="https://hub-ato.com/"
          target="_blank"
          rel="noopener"
          :title="$t('Hub Accounting')"
        >
          <img
            :alt="$t('Hub Accounting')"
            src="@/assets/sponsors/hub-accounting-logo.webp"
          />
        </a>
      </AColumn>
      <AColumn class="p-sponsors__item is-full-mobile is-half-tablet">
        <a
          class="is-block has-text-centered"
          href="https://sloglobal.net/"
          target="_blank"
          rel="noopener"
          :title="$t('Slovenian Global Business Network')"
        >
          <img
            :alt="$t('Slovenian Global Business Network')"
            src="@/assets/sponsors/slovenian-global-business-network-logo.webp"
          />
        </a>
      </AColumn>
      <AColumn class="p-sponsors__item is-full-mobile is-half-tablet">
        <a
          class="is-block has-text-centered"
          href="https://www.gov.si/en/state-authorities/government-offices/government-office-for-slovenians-abroad/"
          target="_blank"
          rel="noopener"
          :title="$t('Republika Slovenija')"
        >
          <img
            :alt="$t('Republika Slovenija')"
            src="@/assets/sponsors/republika-slovenija.webp"
          />
        </a>
      </AColumn>
      <AColumn class="p-sponsors__item is-full-mobile is-5-tablet">
        <a
          class="is-block has-text-centered"
          href="https://www.gov.si/pt/representacoes/embaixada-da-republica-da-eslovenia-brasilia/"
          target="_blank"
          rel="noopener"
          :title="$t('Embaixada Eslovênia - Brasília')"
        >
          <img
            :alt="$t('Embaixada Eslovênia - Brasília')"
            src="@/assets/sponsors/logo-embaixada-eslovenia.webp"
          />
        </a>
      </AColumn>
    </AColumns>
  </SinglePage>
</template>

<script>
import AColumn from "@/components/atoms/Column.vue";
import AColumns from "@/components/atoms/Columns.vue";
import SinglePage from "@/components/templates/SinglePage.vue";

export default {
  components: {
    AColumns,
    AColumn,
    SinglePage
  },
  name: "PAbout"
};
</script>

<style lang="scss" scoped>
h2 {
  color: $regal-blue;

  @include media(">=tablet") {
    margin-top: 1.4rem;
  }
}

.p-sponsors {
  margin: 0 auto;
  max-width: 860px;

  &__item {
    margin-bottom: 1rem;

    @include media(">=tablet") {
      margin-bottom: 1.8rem;
    }

    img {
      max-height: 70px;
      max-width: 100%;
      width: auto;
    }
  }
}
</style>

<template>
  <div class="t-home is-flex is-flex-direction-column">
    <OMarkers>
      <OHeader class="is-flex-shrink-0" />
      <OInteractiveMap class="is-flex-grow-1" />
    </OMarkers>
  </div>
</template>

<script>
import OHeader from "@/components/organisms/Header.vue";
import OInteractiveMap from "@/components/organisms/InteractiveMap.vue";
import OMarkers from "@/components/organisms/Markers.vue";

export default {
  name: "THome",
  components: {
    OHeader,
    OInteractiveMap,
    OMarkers
  }
};
</script>

<style lang="scss">
.t-home {
  min-height: calc(100vh - 1px);

  .o-markers {
    min-height: 100vh;
  }

  .o-header {
    height: auto;

    @include media(">=desktop") {
      min-height: 100px;
    }

    @include media(">=widescreen") {
      max-height: 100px;
    }
  }

  .o-interactive-map {
    height: 76vh;
    padding-bottom: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;

    @include media(">=tablet") {
      height: 73vh;
    }

    @include media(">=desktop") {
      height: calc(100vh - 138px);
    }

    @include media(">=widescreen") {
      height: calc(100vh - 118px);
    }
  }
}
</style>

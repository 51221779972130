<template>
  <div>
    <AColumns
      class="m-header-desktop is-hidden-mobile is-multiline is-vcentered m-0"
    >
      <AColumn class="o-header__title is-narrow is-size-4">
        <h1 class="pl-4 has-text-weight-bold is-uppercase">
          <router-link
            class="m-header-desktop__title"
            :title="$t('Negócios Eslovenos no Brasil')"
            to="/"
          >
            {{ $t("Negócios Eslovenos no Brasil") }}
          </router-link>
        </h1>
      </AColumn>
      <AColumn
        class="is-align-items-center is-flex is-justify-content-center is-2-desktop"
      >
        <img
          :alt="$t('Hub Accounting')"
          class="o-header__logo mb-0"
          src="@/assets/hub-accounting-logo.webp"
        />
      </AColumn>
      <AColumn
        class="is-align-items-center is-flex is-justify-content-center is-2-desktop"
      >
        <img
          :alt="$t('Maluly Jr. Advogados')"
          class="o-header__logo mb-0"
          src="@/assets/maluly-jr-advogados-logo.webp"
        />
      </AColumn>
      <AColumn
        class="is-align-items-center is-flex is-justify-content-center is-3-desktop"
      >
        <img
          :alt="$t('Embaixada Eslovênia - Brasília')"
          class="o-header__logo mb-0"
          src="@/assets/logo-embaixada-eslovenia.webp"
        />
      </AColumn>
      <AColumn
        class="is-align-items-center is-flex is-justify-content-center is-2-desktop"
      >
        <img
          :alt="$t('Slovenian Global Business Network')"
          class="o-header__logo mb-0"
          src="@/assets/slovenian-global-business-network-logo.webp"
        />
      </AColumn>
    </AColumns>
    <nav
      class="c-drawer is-overlay py-2 px-5"
      :class="{ 'c-drawer--open': isSiteMenuOpen }"
    >
      <button
        :class="{
          'c-drawer__toggle--visible': !isSiteMenuOpen
        }"
        class="c-drawer__toggle has-text-centered is-align-items-center is-justify-content-center"
        :title="$t('Menu')"
        type="button"
        @click="toggleMenu"
      >
        <span
          class="navbar-burger is-block ml-0"
          :class="{ close: isSiteMenuOpen }"
          @click.stop="toggleMenu"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span class="is-block is-uppercase">
            {{ isSiteMenuOpen ? $t("Fechar") : $t("Menu") }}
          </span>
        </span>
      </button>
      <AColumns
        class="is-multiline is-mobile mb-2 mt-2 is-justify-content-end mx-0"
      >
        <AColumn class="o-header__languages is-7 pt-0">
          <AColumns class="has-text-centered is-mobile">
            <AColumn class="is-one-third">
              <img
                @click.prevent="() => setLocale('sl')"
                src="@/assets/slovenia.png"
                :alt="$t('Esloveno')"
              />
            </AColumn>
            <AColumn class="is-one-third">
              <img
                @click.prevent="() => setLocale('pt-br')"
                src="@/assets/brazil.png"
                :alt="$t('Português')"
              />
            </AColumn>
            <AColumn class="is-one-third">
              <img
                @click.prevent="() => setLocale('en')"
                src="@/assets/united-states.png"
                :alt="$t('Inglês')"
              />
            </AColumn>
          </AColumns>
        </AColumn>
        <AColumn class="px-0 is-full mt-5">
          <ul class="ml-1">
            <li class="mb-3">
              <router-link :title="$t('Início')" to="/">
                {{ $t("Início") }}
              </router-link>
            </li>
            <li class="mb-3">
              <a
                href=":javascript;"
                :title="$t('Seu local')"
                @click.prevent="resetUserLocation"
                >{{ $t("Seu local") }}</a
              >
            </li>
            <li class="mb-3">
              <router-link :title="$t('Sobre o mapa')" to="/sobre-o-mapa">
                {{ $t("Sobre o mapa") }}
              </router-link>
            </li>
            <li class="mb-3">
              <router-link
                :title="$t('Patrocínios / Apoiadores')"
                to="/patrocinadores"
              >
                {{ $t("Patrocínios / Apoiadores") }}
              </router-link>
            </li>
            <li class="mb-3">
              <a
                href="#contato"
                :title="$t('Entre em contato / Faça parte')"
                @click="toggleMenu"
              >
                {{ $t("Entre em contato / Faça parte") }}
              </a>
            </li>
            <li class="mb-0">
              <router-link :title="$t('Outros países')" to="/countries">
                {{ $t("Outros países") }}
              </router-link>
            </li>
          </ul>
        </AColumn>
        <AColumn
          class="o-header__text has-text-centered is-full is-size-7 mt-5 ml-0 p-0"
        >
          © 2021 - {{ year }} <br />
          {{ $t("Câmara De Comércio ESLOVÊNIA-BRASIL | SLOBRAZ") }}
        </AColumn>
      </AColumns>
    </nav>
  </div>
</template>

<script>
import { computed, inject } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import AColumn from "@/components/atoms/Column.vue";
import AColumns from "@/components/atoms/Columns.vue";
import useResetUserLocation from "@/composables/useResetUserLocation";

export default {
  name: "MHeaderDesktop",
  components: {
    AColumn,
    AColumns
  },
  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale;
    }
  },
  setup() {
    const year = new Date().getFullYear();
    const { isSiteMenuOpen, setIsSiteMenuOpen } = inject("siteMenu");
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const map = computed(() => store.getters["markers/map"]);
    const removeMarker = inject("removeMarker");
    const addMarker = inject("addMarker");

    function resetUserLocation() {
      setIsSiteMenuOpen(false);

      if (route.name !== "Home") {
        router.push("/");
      }

      useResetUserLocation({
        addMarker,
        map: map.value,
        radius: 5,
        removeMarker,
        store,
        t
      });
    }

    function toggleMenu() {
      setIsSiteMenuOpen(!isSiteMenuOpen.value);
    }

    return {
      isSiteMenuOpen,
      resetUserLocation,
      toggleMenu,
      year
    };
  }
};
</script>

<style lang="scss">
.m-header-desktop {
  &__title {
    display: block;

    @include media(">=desktop") {
      max-width: 84%;
    }

    @include media(">=widescreen") {
      max-width: 90%;
    }

    @include media(">=fullhd") {
      max-width: 86%;
    }
  }

  @include media(">=tablet") {
    display: none !important;
  }

  @include media(">=desktop") {
    display: flex !important;
  }
}

.c-drawer {
  position: fixed;
  right: -322px;
  transition: all 200ms 500ms ease-in-out;
  top: 0;
  width: 322px;
  z-index: 9999;

  @include media(">=widescreen") {
    top: 0.75rem;
  }

  &::before {
    background-color: $regal-blue;
    content: "";
    display: block;
    height: 100vh;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    transition: all 200ms ease-in-out;
    width: 100vw;
    z-index: -1;
  }

  &::after {
    background-color: white;
    border-radius: 6px 0 0 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  &__toggle {
    background: none;
    border: none;
    display: flex;
    height: 48px;
    left: -52px;
    padding: 0;
    position: absolute;
    transition: all 200ms ease-in-out;
    top: 0;
    width: 48px;

    @include media(">=desktop") {
      height: 42px;
      left: -66px;
      width: 62px;
    }

    &--visible {
      display: none;

      @include media(">=desktop") {
        display: flex;
      }
    }

    .navbar-burger {
      height: 48px;

      &.close {
        left: 0;
        position: absolute;
        right: 12px;
        transform: translateX(0);

        span {
          &:nth-of-type(1),
          &:nth-of-type(3) {
            top: 25px;
          }

          &:nth-of-type(1) {
            transform: rotate(45deg);
          }

          &:nth-of-type(2) {
            opacity: 0;
          }

          &:nth-of-type(3) {
            transform: rotate(-45deg);
          }
        }
      }

      span {
        @extend %transition-default;
        background-color: $regal-blue;
      }

      &:hover {
        background: none;
      }

      @include media(">=desktop") {
        height: 50px;
      }

      @include media(">=widescreen") {
        height: 62px;
      }

      & > span {
        left: 13px;
        width: 22px;

        @include media(">=desktop") {
          left: 15px;
        }
      }

      & > span:last-child {
        background: none;
        bottom: 0;
        color: $regal-blue;
        font-size: 10px;
        height: auto;
        left: 0;
        position: absolute;
        width: 100%;

        @include media(">=widescreen") {
          bottom: 8px;
        }
      }
    }
  }

  ul {
    width: calc(100% - 2rem);

    li {
      &::marker {
        color: $regal-blue;
      }
    }
  }

  &--open {
    right: 0;

    &::before {
      opacity: 0.6;
      pointer-events: all;
    }

    .c-drawer {
      &__toggle {
        left: 1.3rem;
      }

      .navbar-burger {
        & > span {
          top: 8px !important;
        }
      }
    }
  }
}
</style>

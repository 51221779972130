import api from "@/helpers/api";

export default async ({ dispatch }) => {
  try {
    const response = await api.get("v1/pages");

    if (response.data) {
      dispatch("SET_PAGES", response.data.data);
    }
  } catch (error) {
    dispatch(
      "notification/SET_MESSAGE",
      {
        message: error.message,
        type: "is-danger"
      },
      { root: true }
    );
  }
};

<template>
  <MPreloader class="full-height">
    <MNotification class="full-height">
      <OContactForm
        :open="isContactOpen"
        :onClose="closeContact"
        class="full-height"
      >
        <router-view />
      </OContactForm>
    </MNotification>
  </MPreloader>
</template>

<script>
import "overlayscrollbars/css/OverlayScrollbars.css";
import { computed, onBeforeMount, onMounted, provide, ref, watch } from "vue";
import $ from "jquery";
import MNotification from "@/components/molecules/Notification.vue";
import MPreloader from "@/components/molecules/Preloader.vue";
import OContactForm from "@/components/organisms/Contact.vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "App",
  components: {
    OContactForm,
    MNotification,
    MPreloader
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const isOpen = computed(() => store.getters["menu/isOpen"]);
    const isSiteMenuOpen = ref(false);

    const setIsSiteMenuOpen = value => {
      isSiteMenuOpen.value = value;
    };

    function getPages() {
      return store.dispatch("pages/GET_PAGES");
    }

    function checkContactForm(hash) {
      if (hash === "#contato") {
        store.dispatch("contact/SET_OPEN");
      }
    }

    function handleBodyPageClass(
      { prefix, path } = { prefix: "page-", path: null }
    ) {
      let bodyPageClass = prefix;
      let page = "home";

      if (path) {
        page = path.replace("/", "");
      }

      bodyPageClass += page;

      $("body")
        .removeClass((index, css) =>
          (css.match(/(^|\s)page-\S+/g) || []).join(" ")
        )
        .addClass(bodyPageClass);
    }

    onBeforeMount(() => {
      store.dispatch("menu/UNSET_OPEN");
      store.dispatch("menu/SET_VIEWMODE", "list");
    });

    onMounted(() => {
      getPages();
      handleBodyPageClass();
    });

    watch(
      () => route.hash,
      async hash => {
        checkContactForm(hash);
      }
    );

    watch(
      () => route.path,
      async path => {
        checkContactForm();
        handleBodyPageClass({ path });

        if (isOpen.value && ["About", "Home"].includes(route.name)) {
          store.dispatch("menu/UNSET_OPEN");
        }

        if (isSiteMenuOpen.value) {
          setIsSiteMenuOpen(false);
        }
      }
    );

    function closeContact() {
      document.location.hash = "";
      store.dispatch("contact/UNSET_OPEN");
    }

    provide("siteMenu", {
      isSiteMenuOpen,
      setIsSiteMenuOpen
    });

    return {
      closeContact,
      isContactOpen: computed(() => store.getters["contact/isOpen"])
    };
  }
};
</script>

<style lang="scss">
.grecaptcha-badge {
  z-index: 10;
}

#app,
.full-height {
  min-height: 100vh;
}
</style>

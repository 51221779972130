import VuexPersistence from "vuex-persist";
import { createStore } from "vuex";
import modules from "./modules";

export default createStore({
  modules,
  plugins: [
    new VuexPersistence({ modules: ["menu"], storage: window.localStorage })
      .plugin
  ]
});

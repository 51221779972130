export default function useWaitForGoogle() {
  return new Promise(resolve => {
    function check() {
      setTimeout(() => {
        if (window.google) {
          return resolve();
        }

        check();
      }, 300);
    }

    check();
  });
}

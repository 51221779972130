<template>
  <SinglePage>
    <AContent v-html="$t('page-sobre-o-mapa')" />
  </SinglePage>
</template>

<script>
import AContent from "@/components/atoms/Content.vue";
import SinglePage from "@/components/templates/SinglePage.vue";

export default {
  components: {
    AContent,
    SinglePage
  },
  name: "PAbout"
};
</script>

import { createRouter, createWebHistory } from "vue-router";
import About from "@/components/pages/About.vue";
import Countries from "@/components/pages/Countries.vue";
import Home from "@/components/pages/Home.vue";
import Places from "@/components/organisms/Places.vue";
import Sponsors from "@/components/pages/Sponsors.vue";

const routes = [
  {
    component: Home,
    name: "Home",
    path: "/"
  },
  {
    component: About,
    name: "About",
    path: "/sobre-o-mapa"
  },
  {
    component: Sponsors,
    name: "Sponsors",
    path: "/patrocinadores"
  },
  {
    component: Countries,
    name: "Countries",
    path: "/countries"
  },
  {
    component: Home,
    name: "SearchResults",
    path: "/busca",
    children: [
      {
        component: Places,
        name: "Results",
        path: "",
        alias: "/",
        props: true
      }
    ]
  },
  {
    component: Home,
    children: [
      {
        component: Places,
        name: "Categoria",
        path: "",
        props: true
      }
    ],
    name: "Categorias",
    path: "/categoria/:cat"
  },
  {
    component: Home,
    children: [
      {
        component: Places,
        name: "SubCategoria",
        path: "",
        props: true
      }
    ],
    name: "SubCategorias",
    path: "/categoria/:cat/:subcat"
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  }
});

export default router;

<template>
  <form class="m-search-form" @submit.prevent="submit">
    <div class="control has-icons-right">
      <input
        class="input m-search-form__input"
        type="text"
        :placeholder="$t('Busca')"
        v-model="searchQuery"
      />
      <span
        @click="submit"
        class="icon is-small is-right m-search-form__submit"
      >
        <i class="fas fa-search"></i>
      </span>
    </div>
  </form>
</template>

<script>
import "@fortawesome/fontawesome-free/js/solid";
import "@fortawesome/fontawesome-free/js/fontawesome";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

export default {
  name: "MSearchForm",
  props: {
    onSubmit: {
      type: Function,
      required: false
    }
  },
  setup(props) {
    const router = useRouter();
    const searchQuery = ref("");

    onMounted(() => queryDone());

    function queryDone() {
      if (router.currentRoute.value.query.q) {
        searchQuery.value = router.currentRoute.value.query.q;
      }
    }

    function submit() {
      if (searchQuery.value) {
        if (props.onSubmit) {
          props.onSubmit(searchQuery.value);
        }

        router.push({ path: "/busca", query: { q: searchQuery.value } });
      }
    }

    return {
      searchQuery,
      submit
    };
  }
};
</script>

<style lang="scss">
.m-search-form {
  &__input {
    border-color: rgba($color: $regal-blue, $alpha: 0.4) !important;
    color: $regal-blue !important;
    font-size: 14px !important;
    letter-spacing: 0.5px !important;

    &::placeholder {
      color: rgba($color: $regal-blue, $alpha: 0.5) !important;
    }

    &:focus {
      border-color: $regal-blue !important;
    }
  }

  &__submit {
    cursor: pointer;
    pointer-events: initial !important;
    height: 2.1rem !important;
  }
}
</style>
